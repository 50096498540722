import React, { useEffect, useState, useContext } from "react";
import { sendPostRequest, toastError } from "../../util/util";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import Tag, {instanceOfTag} from "shared-models/build/tag";
import { navigate } from "gatsby";

const ModifyTags = () => {
    const [id, setId] = useState(0);
    const [tag, setTag] = useState({
        Name: "",
        Description: "",
        TagPictureURL: "",
        Verified: false,
    });
    const user = useContext(FirebaseAuthContext);

    useEffect(() => {
        // Get the data
        const params = new URLSearchParams(window.location.search);
        const data = params.get("data");
        if (data === null) return;
        const parsedData = JSON.parse(data);
        // Set the Properties
        if (instanceOfTag(parsedData)) {
            setId(parsedData.Id);
            setTag({
                Name: parsedData.Name,
                Description: parsedData.Description,
                TagPictureURL: parsedData.TagPictureUrl,
                Verified: parsedData.Verified
            });
        }

    }, []);

    const handleChange = (name: string, text: string | boolean) => {
        setTag((prevState) => {
            return {
                ...prevState,
                [name]: text,
            }
        })
    }

    const addOrModifyTag = async () => {
        if (tag.Name.length === 0 || tag.Description.length === 0 || tag.TagPictureURL.length === 0) {
            toastError("Name, description, or picture have a length of 0.");
            return;
        }
        const data : Tag = {
            Name: tag.Name,
            Description: tag.Description,
            TagPictureUrl: tag.TagPictureURL,
            Verified: tag.Verified,
            Id: id,
            Settings: {},
        };
        if (user === null) return;
        const [response, err] = await sendPostRequest(user, '/tags/modify-tag', data, "Successfully added the tag", "Failed to add the tag");
        if (err !== null) {
            console.log(err);
        }
    }

    const deleteTag = async () => {
        const data : Tag = {
            Name: tag.Name,
            Description: tag.Description,
            TagPictureUrl: tag.TagPictureURL,
            Verified: tag.Verified,
            Id: id,
            Settings: {},
        };
        if (user === null) return;
        const [response, err] = await sendPostRequest(user, "/tags/delete-tag", data, "Deleted tag.", "Failed to delete tag");
        if (err !== null) {
            console.log(err);
        }
    }

    return (
        <div className="tw-p-4">
            <div className="tw-mb-4">
                <h4>Tag Name:</h4>
                <input className="tw-input" value={tag.Name} onChange={e => handleChange("Name", e.target.value)} />
            </div>
            <div className="tw-mb-4">
                <h4>Tag Description:</h4>
                <input className="tw-input" value={tag.Description} onChange={e => handleChange("Description", e.target.value)} />
            </div>
            <div className="tw-mb-4">
                <h4>Tag Picture URL:</h4>
                <input className="tw-input" value={tag.TagPictureURL} onChange={e => handleChange("TagPictureURL", e.target.value)} />
            </div>
            <div className="tw-mb-4">
                <h4>Verified</h4>
                <input type="checkbox" checked={tag.Verified} onChange={e => handleChange("Verified", e.target.checked)} />
            </div>
            <div>
                <button className="tw-white-button" onClick={() => addOrModifyTag()}>
                    {
                        id === 0 ?
                        "Add Tag"
                        :
                        "Modify Tag"
                    }
                </button>
            </div>
            <div>
                <button className="tw-red-button tw-mt-4" onClick={async () => {
                    await deleteTag(); 
                    navigate("/admin-team/tags");
                }}>
                    Delete Tag
                </button>
            </div>
        </div>
    )
};

export default ModifyTags;