"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTag = void 0;
const instanceOfTag = (object) => {
    return ('Id' in object &&
        'Name' in object &&
        'Description' in object &&
        'TagPictureUrl' in object &&
        'Verified' in object &&
        'Settings' in object);
};
exports.instanceOfTag = instanceOfTag;
